/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Form from "../components/form"
import SocialIcons from "../components/social-icons"
import { GatsbyImage } from "gatsby-plugin-image"
import { i18n } from "../constants/i18n"
import settings from "../util/site.json"
import PaypalButton from "../components/paypal"

export const pageQuery = graphql`
  query ContactQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 140)
      frontmatter {
        title
        locale
        slug
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1200, height: 675)
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

const Contact = ({ data }) => {
  const { markdownRemark, site } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const Image = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.gatsbyImageData
    : ""
  const { featured_image } = i18n[frontmatter.locale]
  return (
    <Layout
      className="contact-page"
      sx={contactStyles.contactPage}
      locale={frontmatter.locale}
      slug={frontmatter.slug}
      showPartnersBlock={false}
    >
      <Seo
        title={frontmatter.title}
        description={frontmatter.title + " " + site.siteMetadata.title}
      />
      <div className="wrapper home-banner">
        <h1 className="title">{frontmatter.title}</h1>
        {Image ? (
          <GatsbyImage
            image={Image}
            alt={frontmatter.title + " - " + featured_image}
            className="featured-image"
            sx={{ marginTop: "60px", marginBottom: "40px" }}
          />
        ) : (
          ""
        )}
        <PaypalButton locale={frontmatter.locale} />
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: html }}
        />
        <SocialIcons />
        {settings.showForm ? <Form locale={frontmatter.locale} /> : null}
      </div>
    </Layout>
  )
}

export default Contact

const contactStyles = {
  contactPage: {
    input: {
      border: "6px solid",
      borderColor: "inputBorder",
      bg: "inputBackground",
      outline: "none",
    },
    textarea: {
      border: "6px solid",
      borderColor: "inputBorder",
      bg: "inputBackground",
      outline: "none",
    },
  },
}
