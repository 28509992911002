/** @jsx jsx */
import { jsx } from "theme-ui"
import { RiSendPlane2Line } from "react-icons/ri"
import { i18n } from "../constants/i18n"

const Form = ({ locale }) => {
  const {
    contact_page_form_label_name,
    contact_page_form_label_email,
    contact_page_form_label_subject,
    contact_page_form_label_message,
    contact_page_form_button,
  } = i18n[locale]

  return (
    <form
      className="contact-form"
      action="/thanks"
      name="contact"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value="contact" />
      <p>
        <label>
          {contact_page_form_label_name}
          <input type="text" name="name" required />
        </label>
      </p>
      <p>
        <label>
          {contact_page_form_label_email}
          <input type="email" name="email" required />
        </label>
      </p>
      <p>
        <label>
          {contact_page_form_label_subject}
          <input type="text" name="subject" required />
        </label>
      </p>
      <p>
        <label>
          {contact_page_form_label_message}
          <textarea name="message" required />
        </label>
      </p>
      <p className="text-align-right">
        <button
          className="button"
          sx={{
            variant: "variants.button",
          }}
          type="submit"
        >
          {contact_page_form_button}{" "}
          <span className="icon -right">
            <RiSendPlane2Line />
          </span>
        </button>
      </p>
    </form>
  )
}

export default Form
