module.exports = {
  en: {
    path: "/",
    locale: "EN",
    default: true,
    ignore: false,
  },
  pl: {
    path: "/pl",
    locale: "PL",
    ignore: false,
  },
  fr: {
    path: "/fr",
    locale: "FR",
    ignore: false,
  },
  ua: {
    path: "/ua",
    locale: "UA",
    ignore: false,
  },
}
