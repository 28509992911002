/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { RiArrowRightLine, RiArrowLeftLine } from "react-icons/ri"
import { Fancybox, Carousel, Panzoom } from "@fancyapps/ui"
import "@fancyapps/ui/dist/fancybox.css"
import LanguagesItems from "../constants/locales"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { i18n } from "../constants/i18n"

const styles = {
  "article blockquote": {
    "background-color": "cardBg",
  },
  pagination: {
    a: {
      color: "muted",
      "&.is-active": {
        color: "text",
      },
      "&:hover": {
        color: "text",
      },
    },
  },
}

const createPath = ({ slug, locale }) => {
  return LanguagesItems[locale].default
    ? slug
    : LanguagesItems[locale].path + slug
}

const Pagination = ({ previous, next, locale, textPreview, textNext }) => {
  return (
    <div className="pagination -post" sx={styles.pagination}>
      <ul>
        {previous && previous.frontmatter.template === "blog-post" && (
          <li>
            <Link
              to={createPath({ slug: previous.frontmatter.slug, locale })}
              rel="prev"
            >
              <p
                sx={{
                  color: "muted",
                }}
              >
                <span className="icon -left">
                  <RiArrowLeftLine />
                </span>{" "}
                {textPreview}
              </p>
              <span className="page-title">{previous.frontmatter.title}</span>
            </Link>
          </li>
        )}
        {next && next.frontmatter.template === "blog-post" && (
          <li>
            <Link
              to={createPath({ slug: next.frontmatter.slug, locale })}
              rel="next"
            >
              <p
                sx={{
                  color: "muted",
                }}
              >
                {textNext}{" "}
                <span className="icon -right">
                  <RiArrowRightLine />
                </span>
              </p>
              <span className="page-title">{next.frontmatter.title}</span>
            </Link>
          </li>
        )}
      </ul>
    </div>
  )
}

const Post = ({ data, pageContext }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, excerpt } = markdownRemark
  const Image = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.gatsbyImageData
    : ""

  const Gallery =
    frontmatter.gallery !== null
      ? frontmatter.gallery
          .filter(image => {
            return image !== null
          })
          .map((image, index) => {
            const { gatsbyImageData } = image.childImageSharp
            const {
              childImageSharp: {
                gatsbyImageData: {
                  images: {
                    fallback: { src },
                  },
                },
              },
            } = frontmatter.fullWidthGallery[index]
            return {
              source: gatsbyImageData,
              link: src,
            }
          })
      : []

  const { previous, next, locale } = pageContext

  const { blog_post_previous, blog_post_next, blog_post_featured_image } =
    i18n[locale]

  let props = {
    previous,
    next,
    locale,
    textPreview: blog_post_previous,
    textNext: blog_post_next,
  }

  return (
    <Layout
      className="page"
      locale={frontmatter.locale}
      slug={frontmatter.slug}
      showPartnersBlock={false}
    >
      <Seo
        title={frontmatter.title}
        description={
          frontmatter.description ? frontmatter.description : excerpt
        }
        image={Image.images.sources[0].srcSet}
        article={true}
      />
      <article className="blog-post">
        <header className="featured-banner">
          <section className="article-header home-banner">
            <h1 className="title" sx={{ margin: "0 auto 5px !important" }}>
              {frontmatter.title}
            </h1>
            <time sx={{ color: "muted" }}>{frontmatter.date}</time>
          </section>
          {Image ? (
            <GatsbyImage
              image={Image}
              alt={`${frontmatter.title} ${blog_post_featured_image}`}
              className="featured-image"
            />
          ) : (
            ""
          )}
        </header>

        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
        {Gallery.length > 0 ? (
          <div className="grids col-1 sm-2 lg-3">
            {Gallery.map(({ source, link }, index) => {
              const alt = `${frontmatter.title} - photo - ${parseInt(
                index + 1
              )}/${Gallery.length}`
              return (
                <div
                  key={index}
                  className="post-card"
                  sx={{
                    bg: "cardBg",
                  }}
                >
                  {source ? (
                    <a href={link} title={alt} data-fancybox>
                      <GatsbyImage
                        image={source}
                        className="featured-image"
                        alt={alt}
                      />
                    </a>
                  ) : null}
                </div>
              )
            })}
          </div>
        ) : null}
      </article>
      {(previous || next) && <Pagination {...props} />}
    </Layout>
  )
}

export default Post

export const pageQuery = graphql`
  query BlogPostQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 148)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        locale
        slug
        title
        description
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        gallery {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 345, height: 260)
          }
        }
        fullWidthGallery: gallery {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
