/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Link, graphql } from "gatsby"
import { RiArrowRightLine, RiArrowLeftLine } from "react-icons/ri"
import Layout from "../components/layout"
import PostCard from "../components/post-card"
import Seo from "../components/seo"
import { i18n } from "../constants/i18n"

const styles = {
  pagination: {
    a: {
      color: "muted",
      "&.is-active": {
        color: "text",
      },
      "&:hover": {
        color: "text",
      },
    },
  },
}

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!, $locale: String!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: { locale: { eq: $locale }, template: { eq: "blog-post" } }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            locale
            slug
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 345, height: 260)
              }
            }
          }
        }
      }
    }
  }
`
const Pagination = props => (
  <div className="pagination" sx={styles.pagination}>
    <ul>
      {!props.isFirst && (
        <li>
          <Link to={props.prevPage} rel="prev">
            <span className="icon -left">
              <RiArrowLeftLine />
            </span>{" "}
            {props.textPreview}
          </Link>
        </li>
      )}
      {Array.from({ length: props.numPages }, (_, i) => (
        <li key={`pagination-number${i + 1}`}>
          <Link
            to={`${props.blogSlug}${i === 0 ? "" : "/" + (i + 1)}`}
            className={props.currentPage === i + 1 ? "is-active num" : "num"}
          >
            {i + 1}
          </Link>
        </li>
      ))}
      {!props.isLast && (
        <li>
          <Link to={props.nextPage} rel="next">
            {props.textNext}{" "}
            <span className="icon -right">
              <RiArrowRightLine />
            </span>
          </Link>
        </li>
      )}
    </ul>
  </div>
)
class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const { currentPage, numPages, blogSlug, locale } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage =
      currentPage - 1 === 1
        ? blogSlug
        : `${blogSlug}/${(currentPage - 1).toString()}`
    const nextPage = `${blogSlug}/${(currentPage + 1).toString()}`

    const posts = data.allMarkdownRemark.edges
      .filter(edge => !!edge.node.frontmatter.date)
      .map(edge => <PostCard key={edge.node.id} data={edge.node} />)

    const {
      blog_list_title,
      blog_list_previous,
      blog_list_next,
      blog_list_seo_title,
      blog_list_seo_description,
      blog_list_seo_page,
      blog_list_seo_page_of,
    } = i18n[locale]

    let props = {
      isFirst,
      prevPage,
      numPages,
      blogSlug,
      currentPage,
      isLast,
      nextPage,
      textPreview: blog_list_previous,
      textNext: blog_list_next,
    }
    const textPage =
      currentPage !== numPages
        ? ` — ${blog_list_seo_page} ${currentPage} ${blog_list_seo_page_of} ${numPages}`
        : ""
    return (
      <Layout
        className="blog-page"
        locale={locale}
        slug={"/news"}
        showPartnersBlock={true}
      >
        <Seo
          title={`${blog_list_seo_title}${textPage}`}
          description={`${blog_list_seo_description}${textPage}`}
        />
        <div className="home-banner">
          <h1 className="title">{blog_list_title}</h1>
        </div>
        <div className="grids col-1 sm-2 lg-3">{posts}</div>
        {currentPage !== numPages ? <Pagination {...props} /> : null}
      </Layout>
    )
  }
}

export default BlogIndex
