import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

export const pageQuery = graphql`
  query NeedsQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 140)
      frontmatter {
        title
        locale
        slug
      }
    }
  }
`
const NeedsPage = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, excerpt } = markdownRemark

  return (
    <Layout
      className="page"
      locale={frontmatter.locale}
      slug={frontmatter.slug}
      showPartnersBlock={false}
    >
      <Seo title={frontmatter.title} description={excerpt} />
      <div className="wrapper home-banner">
        <h1 className="title">{frontmatter.title}</h1>
        <article dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </Layout>
  )
}

export default NeedsPage
