/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import LanguagesItems from "../constants/locales"

const createPath = ({ slug, locale }) => {
  return LanguagesItems[locale].default
    ? slug
    : LanguagesItems[locale].path + slug
}

const PostCard = ({ data }) => {
  const { slug, locale, title, featuredImage, date } = data.frontmatter
  return (
    <article
      className="post-card"
      sx={{
        bg: "cardBg",
      }}
    >
      {featuredImage ? (
        <Link to={createPath({ slug, locale })}>
          <GatsbyImage
            image={featuredImage.childImageSharp.gatsbyImageData}
            alt={title + " - Featured image"}
            className="featured-image"
          />
        </Link>
      ) : (
        ""
      )}
      <div className="post-content">
        <h2 className="title">
          <Link
            to={createPath({ slug, locale })}
            sx={{
              variant: "links.postLink",
            }}
          >
            {title}
          </Link>
        </h2>
        <p
          className="meta"
          sx={{
            color: "muted",
          }}
        >
          <time>{date}</time>
        </p>
      </div>
    </article>
  )
}

export default PostCard
