import defaultColors from "../util/default-colors.json"
import { lightness } from "@theme-ui/color"
const theme = {
  colors: {
    ...defaultColors,
    text: "#0C111C",
    background: "#FFF",
    primary: "#252525",
    accent: "#2C4D9C",
    muted: "#2C4D9C",
    cardBg: "#E6E7EE",
    borderColor: "#888",
    labelText: "#777",
    inputBorder: "#0070ba",
    inputBackground: "#fff",
    socialIcons: lightness("siteColor", 0.5),
    socialIconsHover: lightness("siteColor", 0.9),
    buttonColor: "#FFF",
    buttonColorBg: "#2C4D9C",
    buttonHoverBg: "#F9DD16",
    buttonHoverColor: "#2C4D9C",
  },
  links: {
    postLink: {
      color: "muted",
      "&:hover": {
        color: "text",
      },
    },
  },
  variants: {
    button: {
      bg: "buttonColorBg",
      color: "buttonColor",
      "&:hover": {
        bg: "buttonHoverBg",
        color: "buttonHoverColor",
      },
    },
    socialIcons: {
      a: {
        color: "socialIcons",
        ":hover": {
          color: "socialIconsHover",
        },
      },
    },
  },
}

export default theme
