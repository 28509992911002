/** @jsx jsx */
import { jsx } from "theme-ui"
import partners from "../util/partners.json"
import { i18n } from "../constants/i18n"
import { Link } from "gatsby"

const Partners = ({ locale }) => {
  const partnersArray = Object.keys(partners).map(type => {
    const title = i18n[locale][`partner_${type}`]
    return partners[type].map((item, index) => {
      return (
        <div className="logo" key={index}>
          <div className="image">
            <img src={item.image} alt={item.title} />
          </div>
          <div className="title">{title}</div>
        </div>
      )
    })
  })
  return (
    <div className="partners">
      <h2 className="title">{i18n[locale].partner_title}</h2>
      <div className="list-of-partners">{partnersArray}</div>
    </div>
  )
}

export default Partners
