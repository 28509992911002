import React from "react"
import { Link } from "gatsby"
import { RiArrowLeftSLine, RiBugLine, RiSkullLine } from "react-icons/ri"

import Seo from "../components/seo"
import Layout from "../components/layout"
import { i18n } from "../constants/i18n"

const NotFound = () => {
  const locale = "en" // todo: need to connect actual localization
  const {
    page_404_seo,
    page_404_title,
    page_404_message,
    page_404_button_back_home,
    page_404_button_report_this,
  } = i18n[locale]
  return (
    <Layout className="not-found-page" locale={locale} slug={"/404"}>
      <Seo title={page_404_seo} />
      <div
        className="wrapper"
        style={{
          textAlign: "center",
        }}
      >
        <header>
          <RiSkullLine
            style={{
              fontSize: "128px",
              color: "var(--primary-color)",
            }}
          />
          <h1>{page_404_title}</h1>
          <p>{page_404_message}</p>
        </header>
        <Link to="/" className="button">
          <RiArrowLeftSLine className="icon -left" />
          {page_404_button_back_home}
        </Link>
        <Link to="/contact" className="button -outline">
          {page_404_button_report_this} <RiBugLine className="icon -right" />
        </Link>
      </div>
    </Layout>
  )
}

export default NotFound
