/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Link } from "gatsby"
import LanguagesItems from "../constants/locales"

const createPath = ({ slug, locale }) => {
  const path = slug === "/" && locale !== "en" ? "" : slug
  return LanguagesItems[locale].default
    ? path
    : LanguagesItems[locale].path + path
}

const ListLink = props => {
  return (
    <li>
      <Link to={props.to} className={props.active ? "active" : null}>
        {props.children}
      </Link>
    </li>
  )
}

class Languages extends React.Component {
  render() {
    const { locale } = this.props
    const listLanguagesItems = Object.keys(LanguagesItems)
      .filter(lang => {
        return !LanguagesItems[lang].ignore
      })
      .map((lang, index) => (
        <ListLink
          active={locale === lang}
          key={index}
          to={createPath({ slug: this.props.pathname, locale: lang })}
        >
          {LanguagesItems[lang].locale}
        </ListLink>
      ))
    return <ul className="languages-navigation">{listLanguagesItems}</ul>
  }
}

export default Languages
