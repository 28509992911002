/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { RiArrowDownLine, RiArrowRightSLine } from "react-icons/ri"

import PostCard from "./post-card"
import LanguagesItems from "../constants/locales"
import { i18n } from "../constants/i18n"

const createPath = ({ slug, locale }) => {
  return LanguagesItems[locale].default
    ? slug
    : LanguagesItems[locale].path + slug
}

export default function BlogListHome(props) {
  const { data, locale } = props
  const posts = data.edges
    .filter(edge => !!edge.node.frontmatter.date)
    .map(edge => <PostCard key={edge.node.id} data={edge.node} />)
  return <PostMaker data={posts} locale={locale} />
}

const PostMaker = ({ data, locale }) => {
  const {
    blog_list_home_latest_in,
    blog_list_home_blog,
    blog_list_home_see_more,
  } = i18n[locale]
  return (
    <section className="home-posts">
      <h2>
        {blog_list_home_latest_in} <strong>{blog_list_home_blog}</strong>{" "}
        <span className="icon -right">
          <RiArrowDownLine />
        </span>
      </h2>
      <div className="grids col-1 sm-2 lg-3">{data}</div>
      <Link
        className="button"
        to={createPath({ slug: "/news", locale })}
        sx={{
          variant: "variants.button",
        }}
      >
        {blog_list_home_see_more}
        <span className="icon -right">
          <RiArrowRightSLine />
        </span>
      </Link>
    </section>
  )
}
