/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Link } from "gatsby"
import { RiMenu3Line, RiCloseLine } from "react-icons/ri"
import Languages from "./languages"
import LanguagesItems from "../constants/locales"
import { i18n } from "../constants/i18n"
import settings from "../util/site.json"

const MenuItems = [
  {
    path: "/",
    key: "menu_home",
    show: true,
  },
  {
    path: "/about",
    key: "menu_about",
    show: true,
  },
  {
    path: "/news",
    key: "menu_blog",
    show: settings.showNews,
  },
  {
    path: "/needs",
    key: "menu_needs",
    show: true,
  },
  {
    path: "/donate",
    key: "menu_contact",
    show: true,
  },
]

const createPath = ({ slug, locale }) => {
  const path = slug === "/" && locale !== "en" ? "" : slug
  return LanguagesItems[locale].default
    ? path
    : LanguagesItems[locale].path + path
}

const ListLink = props => (
  <li>
    <Link to={props.to}>{props.children}</Link>
  </li>
)

class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showMenu: false }
    this.handleToggleClick = this.handleToggleClick.bind(this)
  }

  handleToggleClick() {
    this.setState(state => ({
      showMenu: !state.showMenu,
    }))
  }

  render() {
    const { locale } = this.props
    const listMenuItems = MenuItems.filter(({ show }) => {
      return show
    }).map((menuItem, index) => {
      const title = i18n[locale][menuItem.key]
      return (
        <ListLink key={index} to={createPath({ slug: menuItem.path, locale })}>
          {title}
        </ListLink>
      )
    })
    return (
      <nav className="site-navigation" sx={navStyle.menu}>
        <button
          onClick={this.handleToggleClick}
          className={"menu-trigger" + (this.state.showMenu ? " is-active" : "")}
        >
          <div className="icon-menu-line">
            <RiMenu3Line />
          </div>
          <div className="icon-menu-close">
            <RiCloseLine />
          </div>
        </button>
        <ul>
          {listMenuItems}
          <div sx={navStyle.border} />
          <div sx={navStyle.theme}>
            <Languages pathname={this.props.pathname} />
          </div>
        </ul>
      </nav>
    )
  }
}

export default Navigation

const navStyle = {
  menu: {
    ul: {
      bg: "siteColor",
    },
  },
  theme: {
    display: ["block", "block", "block", "none"],
    p: " 0 20px 0",
  },
  border: {
    bg: "borderColor",
    borderTop: "1px solid transparent",
    display: ["block", "block", "block", "none"],
  },
}
