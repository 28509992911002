import React from "react"
import { Link } from "gatsby"
import { RiArrowLeftSLine, RiCheckboxCircleLine } from "react-icons/ri"

import Seo from "../components/seo"
import Layout from "../components/layout"
import { i18n } from "../constants/i18n"

const Thanks = () => {
  const locale = "en" // todo: need to connect actual localization
  const { thanks_seo, thanks_title, thanks_message, thanks_button_back_home } =
    i18n[locale]
  return (
    <Layout className="thanks-page" locale={"en"} slug={"/thanks"}>
      <Seo title={thanks_seo} />
      <div
        className="wrapper"
        style={{
          textAlign: "center",
        }}
      >
        <RiCheckboxCircleLine
          style={{
            fontSize: "128px",
            color: "var(--primary-color)",
          }}
        />
        <h1>{thanks_title}</h1>
        <p>{thanks_message}</p>
        <Link to="/" className="button">
          <RiArrowLeftSLine className="icon -left" />
          {thanks_button_back_home}
        </Link>
      </div>
    </Layout>
  )
}

export default Thanks
