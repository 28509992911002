import enTranslations from "./en.json"
import uaTranslations from "./ua.json"
import plTranslations from "./pl.json"
import frTranslations from "./fr.json"
export const i18n = {
  en: enTranslations,
  ua: uaTranslations,
  pl: plTranslations,
  fr: frTranslations,
}
