/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { i18n } from "../constants/i18n"

const Footer = ({ locale }) => {
  const {
    footer_copyright_part_1,
    footer_copyright_part_2,
    footer_copyright_part_3_link,
    footer_copyright_part_4_link_title,
  } = i18n[locale]
  return (
    <footer
      className="site-footer"
      sx={{
        bg: "siteColor",
      }}
    >
      <div className="container">
        <p>
          {footer_copyright_part_1} {footer_copyright_part_2}{" "}
          <Link to={footer_copyright_part_3_link} target="_blank">
            {footer_copyright_part_4_link_title}
          </Link>
        </p>
      </div>
    </footer>
  )
}

export default Footer
