import React from "react"
import { Link } from "gatsby"
import LanguagesItems from "../constants/locales"
import LogoSvg from "/static/assets/4Ukraine_logo.svg"

const createPath = ({ slug, locale }) => {
  const path = slug === "/" && locale !== "en" ? "" : slug
  return LanguagesItems[locale].default
    ? path
    : LanguagesItems[locale].path + path
}

const Logo = props => {
  return (
    <div className="site-logo">
      <Link to={createPath({ slug: "/", locale: props.locale })}>
        <img src={LogoSvg} alt={props.title} />
      </Link>
    </div>
  )
}

export default Logo
