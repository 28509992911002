/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Logo from "./logo"
import Navigation from "./navigation"

import "../assets/scss/style.scss"
import Footer from "./footer"

import Languages from "./languages"
import Partners from "./partners"

const query = graphql`
  query LayoutQuery {
    site {
      siteMetadata {
        siteTitle: title
      }
    }
    siteSearchIndex {
      index
    }
  }
`

const Layout = ({
  children,
  className,
  locale,
  slug,
  primaryClassName,
  showPartnersBlock,
}) => {
  const { site } = useStaticQuery(query)
  const { siteTitle } = site.siteMetadata
  return (
    <div className={"primary-container " + primaryClassName}>
      <Header>
        <Logo title={siteTitle} locale={locale} />
        <div sx={layoutStyle.nav}>
          <Navigation pathname={slug} locale={locale} />
        </div>
        <div sx={layoutStyle.appearance}>
          <Languages pathname={slug} locale={locale} />
        </div>
      </Header>
      <main className={"container " + className}>{children}</main>
      {showPartnersBlock ? (
        <div className="partners-wrapper">
          <div className={"container " + className}>
            <Partners locale={locale} />
          </div>
        </div>
      ) : null}
      <Footer locale={locale} />
    </div>
  )
}

export default Layout

const layoutStyle = {
  appearance: {
    display: ["none", "none", "none", "flex"],
    alignItems: "center",
    gap: 4,
  },
  nav: {
    display: "flex",
    alignItems: "center",
    gap: 4,
  },
}
