/** @jsx jsx */
import { jsx } from "theme-ui"
import paypalButtons from "../util/paypal.json"
import paypalLogo from "/static/assets/paypalLogo.svg"

const PaypalButton = () => {
  const { donationButtons } = paypalButtons
  if (donationButtons && donationButtons.length > 0) {
    return (
      <div className="description">
        <h2>PayPal:</h2>
        <div
          style={{
            textAlign: "center",
          }}
        >
          {donationButtons.map(({ hosted_button_id, currency }) => (
            <a
              key={hosted_button_id}
              href={`https://www.paypal.com/donate/?hosted_button_id=${hosted_button_id}`}
              className="paypal-button"
              target="_blank"
            >
              <img src={paypalLogo} />
              Donate {currency}
            </a>
          ))}
        </div>
      </div>
    )
  }
  return null
}

export default PaypalButton
